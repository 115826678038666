import React from 'react'
import { Container } from 'react-bootstrap'
import * as s from './Mission.module.scss'

const Mission = ({ heading1, text1, heading2, text2 }) => {
  return (
    <div className={s.mission}>
      <Container className={s.mission_inner}>
        <div>
          <h2>{heading1}</h2>
          <p className={s.mission_descr}>{text1}</p>
        </div>

        <div>
          <h2>{heading2}</h2>
          <p className={s.mission_descr}>{text2}</p>
        </div>
      </Container>
    </div>
  )
}

export default Mission
