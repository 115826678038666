import React from 'react'
import { Container } from 'react-bootstrap'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as s from './Hero.module.scss'

const Hero = (props) => {
  const { heading, leadParagraph, textParagraph, image, statistics } = props

  return (
    <div className={s.hero}>
      <Container>
        <div className={s.hero_inner}>
          <div className={s.hero_content}>
            <h1 className={s.hero_title}>{heading}</h1>
            <p className={s.hero_subtitle}>{leadParagraph}</p>
            <p className={s.hero_descr}>{textParagraph}</p>
          </div>
          <div className={s.hero_pic}>
            <GatsbyImage image={image?.gatsbyImage} alt={heading} />
          </div>
        </div>
        <ul className={s.hero_stats}>
          {statistics?.map(({ figure, description }) => (
            <li key={figure} className={s.hero_stats_item}>
              <big className={s.hero_stats_value}>{figure}</big>
              <span className={s.hero_stats_title}>{description}</span>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  )
}

export default Hero
