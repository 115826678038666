import React from 'react'
import Layout from '~components/Layout'
import Reviews from '~components/Reviews'
import Partners from '~components/Partners'
import Cta from '~components/Cta'
import Hero from './components/Hero'
import Mission from './components/Mission'
import Values from './components/Values'
import Team from './components/Team'

import S from '../../components/seo'

const About = (props) => {
  const {
    header,
    headerMobileExtras,
    footer,
    socials,
    cellar,
    contactInfo,
    cookiesOffcanvas,
    bookingForm,
    bookADemo,
    formMessages,
    seo,
    sectionHeroAbout,
    sectionMissionVision,
    sectionValues,
    sectionContactForm,
    sectionTeam,
    sectionReviews,
    sectionPartners,
    contactUsForm,
    reviews,
  } = props

  return (
    <Layout
      withBooking
      mode="secondary"
      {...{
        header,
        headerMobileExtras,
        footer,
        socials,
        cellar,
        contactInfo,
        cookiesOffcanvas,
        bookingForm,
        bookADemo,
        formMessages,
        seo,
        sectionHeroAbout,
        sectionMissionVision,
        sectionValues,
        sectionContactForm,
        sectionTeam,
        sectionReviews,
        sectionPartners,
        contactUsForm,
        reviews,
      }}
    >
      <S {...seo} />
      {sectionHeroAbout && <Hero {...sectionHeroAbout} />}
      {sectionMissionVision && <Mission {...sectionMissionVision} />}
      {sectionValues && <Values {...sectionValues} />}
      {sectionContactForm && (
        <Cta
          sectionContactForm={sectionContactForm}
          formMessages={formMessages}
          socials={socials}
          contactUsForm={contactUsForm}
        />
      )}
      {sectionTeam && <Team {...sectionTeam} />}
      {sectionReviews && (
        <Reviews {...sectionReviews} reviews={reviews.nodes} />
      )}
      {sectionPartners && <Partners {...sectionPartners} />}
    </Layout>
  )
}

export default About
