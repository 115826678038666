import React from 'react'
import { graphql } from 'gatsby'
import About from '~containers/About'

const AboutPage = ({ data }) => (
  <About
    seo={data.wpPage.seo}
    {...data.wpPage.aboutUs}
    {...data.wpPage.common}
    reviews={data.reviews}
    header={data.header}
    headerMobileExtras={data.headerMobileExtras}
    footer={data.footer}
    socials={data.socials}
    cellar={data.cellar}
    contactInfo={data.contactInfo}
    cookiesOffcanvas={data.cookiesOffcanvas}
    bookingForm={data.bookingForm}
    bookADemo={data.bookADemo}
    formMessages={data.formMessages}
    contactUsForm={data.contactUsForm}
    sectionContactForm={data.sectionContactForm}
  />
)

export const query = graphql`
  query About(
    $headerSlug: String
    $headerMobileExtrasSlug: String
    $footerSlug: String
    $socialSlug: String
    $cellarSlug: String
    $cookiesOffcanvasSlug: String
    $bookingFormSlug: String
    $bookADemoSlug: String
    $formMessagesSlug: String
    $aboutUsSlug: String
    $contactUsFormSlug: String
    $sectionContactFormSlug: String
    $code: String
  ) {
    header: wpMenu(slug: { eq: $headerSlug }) {
      headerExtras {
        headerButtonPrimary {
          text
          url
        }
      }
      menuItems {
        nodes {
          parentId
          label
          url
          navigationMenu {
            navRel
            navTarget
            navIsNotLocalizable
          }
          navigationHeader {
            headerNavIsDesktopOnly
            headerNavSubMenuTitle
            headerNavDescription
          }
          childItems {
            nodes {
              label
              url
              navigationMenu {
                navTarget
                navRel
                navIsNotLocalizable
              }
              navigationHeader {
                headerNavIconName
                headerNavDescription
              }
            }
          }
        }
      }
    }

    headerMobileExtras: wpMenu(slug: { eq: $headerMobileExtrasSlug }) {
      menuItems {
        nodes {
          label
          url
          navigationMenu {
            navRel
            navTarget
            navIsNotLocalizable
          }
        }
      }
    }

    footer: wpMenu(slug: { eq: $footerSlug }) {
      menuItems {
        nodes {
          label
          url
          navigationMenu {
            navRel
            navTarget
            navIsNotLocalizable
          }
        }
      }
    }
    socials: wpMenu(slug: { eq: $socialSlug }) {
      menuItems {
        nodes {
          label
          url
          navigationMenu {
            navRel
            navTarget
          }
          navigationSocial {
            navIconName
          }
        }
      }
    }
    cellar: wpMenu(slug: { eq: $cellarSlug }) {
      menuItems {
        nodes {
          label
          url
        }
      }
      cellarExtras {
        languageLink {
          text
          url
          target
          rel
          isNotLocalizable
        }
      }
    }
    contactInfo: wpComponent(slug: { eq: "contact-info" }) {
      contactInfo {
        contactInfo {
          address
          email
          phone
        }
      }
    }

    cookiesOffcanvas: wpComponent(slug: { eq: $cookiesOffcanvasSlug }) {
      cookiesOffcanvas {
        cookiesOffcanvas: cookiesoffcanvas {
          heading
          text
          buttonSecondary {
            text
            url
          }
          buttonPrimary {
            text
          }
        }
      }
    }

    sectionContactForm: wpComponent(slug: { eq: $sectionContactFormSlug }) {
      sectionContactForm {
        sectionContactForm {
          address
          email
          heading
          phone
          text
        }
      }
    }

    bookingForm: wpForm(id: { eq: $bookingFormSlug }) {
      formId
      fetchedFields {
        fieldId
        label
        type
        required
      }
    }
    bookADemo: wpComponent(slug: { eq: $bookADemoSlug }) {
      bookADemo {
        bookADemo {
          heading
          text
        }
      }
    }
    formMessages: wpComponent(slug: { eq: $formMessagesSlug }) {
      formMessages {
        formMessages {
          bookADemo {
            success
            error
          }
          contactUs {
            success
            error
          }
        }
      }
    }

    wpPage(slug: { eq: $aboutUsSlug }) {
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
        opengraphTitle
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
      aboutUs {
        sectionHeroAbout {
          heading
          leadParagraph
          textParagraph
          image {
            gatsbyImage(quality: 90, width: 680, height: 454)
          }
          statistics {
            figure
            description
          }
        }
        sectionMissionVision {
          heading1
          text1
          heading2
          text2
        }
        sectionValues {
          heading
          paragraphs {
            text
          }
        }
        sectionTeam {
          heading
          teamMembers {
            name
            position
            textAbout
            image {
              gatsbyImage(quality: 90, width: 1024, height: 1024)
            }
          }
        }
      }
      common {
        sectionReviews: sectionTestimonials {
          heading
        }
        sectionPartners: sectionClients {
          heading
        }
      }
    }
    contactUsForm: wpForm(id: { eq: $contactUsFormSlug }) {
      formId
      fetchedFields {
        fieldId
        label
        type
        required
      }
    }

    reviews: allWpTestimonial(
      filter: { testimonial: { code: { eq: $code } } }
      sort: { order: ASC, fields: date }
    ) {
      nodes {
        review: testimonial {
          author {
            name
            occupation
            company
            location
            avatarImage {
              gatsbyImage(width: 96, height: 96)
            }
          }
          testimonialText
        }
      }
    }
  }
`

export default AboutPage
