import React from 'react'
import { Container } from 'react-bootstrap'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as s from './Team.module.scss'

const Team = ({ heading, teamMembers }) => {
  return (
    <Container className={s.team}>
      <h2>{heading}</h2>
      <div className={s.team_list}>
        {teamMembers?.map(({ name, position, textAbout, image }) => (
          <div key={name} className={s.team_item}>
            <div>
              <p className={s.team_title}>
                {name}
                <br />
                {position}
              </p>
              <p className={s.team_descr}>{textAbout}</p>
            </div>
            <div className={s.team_pic}>
              <GatsbyImage image={image?.gatsbyImage} alt={name} />
            </div>
          </div>
        ))}
      </div>
    </Container>
  )
}

export default Team
