// extracted by mini-css-extract-plugin
export var hero = "wk_Ml";
export var hero_content = "wk_Ql";
export var hero_descr = "wk_Tl";
export var hero_inner = "wk_Nl";
export var hero_pic = "wk_Pl";
export var hero_stats = "wk_Vl";
export var hero_stats_item = "wk_Wl";
export var hero_stats_title = "wk_Yl";
export var hero_stats_value = "wk_Xl";
export var hero_subtitle = "wk_Sl";
export var hero_title = "wk_Rl";