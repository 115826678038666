import React from 'react'
import { Container } from 'react-bootstrap'
import * as s from './Values.module.scss'

const Values = ({ heading, paragraphs }) => {
  return (
    <div className={s.values}>
      <Container>
        <h2>{heading}</h2>
        <div className={s.values_descr}>
          {paragraphs?.map(({ text }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={`p${i}`}>{text}</p>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Values
